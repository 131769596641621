





import { defineComponent, ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import { useContentRouter } from '@wemade-vsf/composables'

export default defineComponent({
  name: 'PbSlide',
  props: {
    dataAppearance: {
      type: String,
      default: 'poster'
    }
  },
  setup () {
    const el = ref<HTMLInputElement | null>(null);
    const { bind, unbind } = useContentRouter();

    onMounted(() => {
      if (!el.value) return;
      bind(el)
    })

    onBeforeUnmount(() => {
      if (!el.value) return;
      unbind(el)
    })

    return {
      el
    }
  }
})
