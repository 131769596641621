import { render, staticRenderFns } from "./PbSlider.vue?vue&type=template&id=88a239dc&scoped=true&"
import script from "./PbSlider.vue?vue&type=script&lang=ts&"
export * from "./PbSlider.vue?vue&type=script&lang=ts&"
import style0 from "./PbSlider.vue?vue&type=style&index=0&id=88a239dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88a239dc",
  null
  
)

export default component.exports